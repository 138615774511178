
import * as ipxRuntime$pCuG9RxKEogiRsXpC4HpIMje2V0oddVtaJJ2BNw5Ug4 from '/home/runner/work/divine/divine/node_modules/.pnpm/@nuxt+image@1.10.0_@azure+storage-blob@12.27.0_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as twicpicsRuntime$_45Ouu4_KCd0pCEbf9bBEQS96TeYMtSHT60tWvsit4cVQ from '/home/runner/work/divine/divine/node_modules/.pnpm/@nuxt+image@1.10.0_@azure+storage-blob@12.27.0_db0@0.3.1_better-sqlite3@11.9.1__ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/twicpics'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "small": {
      "modifiers": {
        "width": 80,
        "height": 80,
        "quality": 90
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "divineteststoragehot.blob.core.windows.net",
    "divinesamples.twic.pics"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$pCuG9RxKEogiRsXpC4HpIMje2V0oddVtaJJ2BNw5Ug4, defaults: undefined },
  ['twicpics']: { provider: twicpicsRuntime$_45Ouu4_KCd0pCEbf9bBEQS96TeYMtSHT60tWvsit4cVQ, defaults: undefined }
}
        